import {
  clearAllBodyScrollLocks as clearAllLocks,
  disableBodyScroll,
  enableBodyScroll as enableScroll,
  type BodyScrollOptions,
} from 'body-scroll-lock-upgrade';
import { useRef, type RefObject } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

type Options = {
  preserveSticky?: boolean;
} & BodyScrollOptions;

/**
 * Custom hook to lock or unlock body scroll on a specified target element.
 *
 * @param isLocked - An optional boolean indicating whether to lock (true) or unlock (false) the body scroll.
 * @param target - An optional React ref object pointing to the target HTML element that scrolling should be persisted for (such as a modal/lightbox/flyout/nav). The target element is the one we would like to allow scroll on (NOT a parent of that element). This is also the element to apply the CSS `-webkit-overflow-scrolling: touch;` if desired.
 * @param options - Optional configuration for body scroll lock. Defaults to `{ reserveScrollBarGap: true }`.
 * @remarks The `preserveSticky` option uses `overflow: clip` in modern browsers and falls back to `overflow: hidden`
 * in browsers that don't support it. Note that `overflow: clip` is not supported in older browsers.
 * @returns An object containing methods to clear all locks, disable scroll, and enable scroll.
 *
 * @example
 * const Modal = ({ isOpen }: { isOpen: boolean }) => {
 *   const refModal = useRef<HTMLDivElement>(null);
 *
 *   useBodyScrollLock(isOpen, refModal, { preserveSticky: true });
 *
 *   return <div ref={refModal}>Modal content</div>;
 * };
 */
export const useBodyScrollLock = (
  isLocked?: boolean,
  target?: RefObject<HTMLElement>,
  options: Options = { reserveScrollBarGap: true }
) => {
  const refTimeout = useRef<ReturnType<typeof requestAnimationFrame> | undefined>(undefined);

  const disableScroll = (target: HTMLElement, options: Options) => {
    const { preserveSticky, ...scrollOptions } = options;
    const supportsClip = CSS.supports('overflow: clip');

    cancelAnimationFrame(refTimeout.current);
    disableBodyScroll(target, scrollOptions);

    // preserve sticky positioning of elements on the page (not in modal itself - sticky elements there aren't affected) when body scroll is disabled
    if (preserveSticky) {
      refTimeout.current = requestAnimationFrame(() => {
        document.body.style.overflow = supportsClip ? 'clip' : 'hidden';
      });
    }
  };

  useIsomorphicLayoutEffect(() => {
    if (!target?.current || typeof isLocked === 'undefined') return;

    if (isLocked) {
      disableScroll(target.current, options);
    } else {
      enableScroll(target.current);
    }

    return () => {
      cancelAnimationFrame(refTimeout.current);
      clearAllLocks();
    };
  }, [isLocked, target, options]);

  return { clearAllLocks, disableScroll, enableScroll };
};
