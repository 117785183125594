import { type MutableRefObject, useEffect, useRef, useState } from 'react';

export type UseClampedTextType<T extends HTMLElement> = {
  ref: MutableRefObject<T>;
  scrollHeight: number;
  clientHeight: number;
  overrideMinHeight: number;
  isExpandable: boolean;
  isExpanded: boolean;
  handleToggleExpanded: (_: { scrollToTop?: boolean }) => void;
};

export function useClampedText<T extends HTMLElement>(props?: {
  overrideMinHeight?: number;
}): UseClampedTextType<T> {
  const overrideMinHeight = props?.overrideMinHeight;

  const ref = useRef<T>(null);

  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const handleClamping: ResizeObserverCallback = (entries) => {
      const element = entries?.[0].target;

      if (!element) {
        console.error('ResizeObserverCallback: No element found');
        return;
      }

      const { scrollHeight, clientHeight } = element;

      const calcClientHeight =
        overrideMinHeight && overrideMinHeight <= clientHeight ? overrideMinHeight : clientHeight;

      setScrollHeight(scrollHeight);
      setClientHeight(calcClientHeight);

      setIsExpandable(scrollHeight > calcClientHeight || isExpanded);
    };

    if (!window.ResizeObserver) return;
    const resizeObserver = new ResizeObserver(handleClamping);
    resizeObserver.observe(ref.current);

    return () => resizeObserver?.disconnect();
  }, [isExpanded, overrideMinHeight]);

  /**
   * Handles the expanded/collapsed state of the content
   *
   * If the content is expanded, the top of the content is above the viewport, and the overall
   * height of the content is larger than the viewport, the content will scroll to the top.
   * This is to avoid collapsing the content and the user being left further down the page
   * than their initial position.
   *
   * @param scrollToTop - If true, the content will scroll to the top when expanded
   * @returns void
   */
  const handleToggleExpanded = ({ scrollToTop }: { scrollToTop?: boolean }) => {
    let shouldScrollTop = false;
    if (ref.current) {
      const { top, height } = ref.current.getBoundingClientRect();
      const isAboveViewport = top < 0;
      const isLargerThanViewport = height > window.innerHeight;

      shouldScrollTop = isAboveViewport && isLargerThanViewport;
    }

    setIsExpanded((prev) => {
      if (ref.current && prev && scrollToTop && shouldScrollTop) {
        ref.current.scrollIntoView({ block: 'start', behavior: 'instant' as ScrollBehavior });
      }
      return !prev;
    });
  };

  return {
    ref,
    scrollHeight,
    clientHeight,
    overrideMinHeight,
    isExpandable,
    isExpanded,
    handleToggleExpanded,
  };
}
