import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { DM_Serif_Text, DM_Serif_Display, Inter } from 'next/font/google';

// to prevent flickering
import '../styles/fonts.css';

//Tailwind global styles
import '../../../libs/tailwind/globals.css';

// utils
import { PrismicPreview } from '@prismicio/next';
import { PageContext } from '../components/PageContext';
import { initializeGtm } from '../utils/initializeGtm';
import Layout from '../components/Layout';

const PRISMIC_REPOSITORY_NAME = process.env.PRISMIC_REPOSITORY_NAME;

const dmSerifText = DM_Serif_Text({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-serif',
  style: ['normal', 'italic'],
});

const dmSerifDisplay = DM_Serif_Display({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-serif-display',
  style: ['normal', 'italic'],
});

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-inter',
  display: 'swap',
});

const App = ({ Component, pageProps }: AppProps) => {
  const [isDarkTheme, setIsDarkTheme] = useState(pageProps.isDarkTheme);

  initializeGtm();

  /**
   * Dark Theme: adds "dark" class to html element when pageProps.isDarkTheme is enabled
   */
  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    setIsDarkTheme(pageProps.isDarkTheme);
  }, [isDarkTheme, pageProps.isDarkTheme]);

  return process.env.NODE_ENV !== 'development' ? (
    <div className={`${dmSerifText.variable} ${inter.variable} ${dmSerifDisplay.variable}`}>
      <PageContext>
        <Layout
          topProducts={pageProps.topProducts}
          preview={null}
          initialNavItems={pageProps.initialNavItems}
          trackingPageType={pageProps?.trackingPageType}
        >
          <Component {...pageProps} />
        </Layout>
      </PageContext>
    </div>
  ) : (
    <div className={`${dmSerifText.variable} ${inter.variable} ${dmSerifDisplay.variable}`}>
      <PrismicPreview repositoryName={PRISMIC_REPOSITORY_NAME}>
        <PageContext>
          <Layout
            topProducts={pageProps.topProducts}
            preview={pageProps.preview}
            initialNavItems={pageProps.initialNavItems}
            trackingPageType={pageProps?.trackingPageType}
          >
            <Component {...pageProps} />
          </Layout>
        </PageContext>
      </PrismicPreview>
    </div>
  );
};

export default App;
