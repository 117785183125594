import { PromoService, vedPromosConfig } from '../../promos';
import { getLocationInfo, getPriceInfo } from '../productInfo';
import { roundPrice } from '../roundPrice';

import type { ProductCardType, ProductMapType } from './types';

const promoService = new PromoService({
  activePromos: process.env.NEXT_PUBLIC_ACTIVE_PROMOS as string,
  promosConfiguration: vedPromosConfig,
});

/**
 * Takes in a productMap object and formats its entries to an array of ProductCardType objects.
 *
 * @param {Record<string, any>} productMap - The productMap object containing product data.
 * @returns {ProductCardType[]} An array of ProductCardType objects, where each object contains information about a single product card.
 */
export const formatTopProductsToCardsArray = (
  productMap: Record<string, ProductMapType>
): ProductCardType[] => {
  const productsEntries = Object.entries(productMap);

  const formattedCards = productsEntries.map(([key, data]) => {
    const {
      id,
      media,
      title: cardTitle,
      isCollectionProduct,
      reviews,
      isNew,
      isExclusive,
      categories,
      promocode,
      sku,
    } = data;
    let productPromo = data?.productPromo || null;
    const { currentPrice, pastPrice, badge } = getPriceInfo(data) ?? {};

    // non-Algolia products will already have assigned `productPromos`
    // those set in Prismic will not have been processed yet
    if (!productPromo) productPromo = promoService.getProductPromo(data);

    return {
      id,
      badge,
      // used by variation A (old product card)
      cardImage: media?.mainImage?.url ?? null,
      // used by variation B (product card 2024)
      cardImages: [media?.mainImage, ...(media?.images || [])].map((img) => ({
        src: img?.url || '',
        alt: img?.alt || cardTitle,
      })),
      cardFullSku: promocode ? `${sku} ${promocode}` : sku,
      cardRoute: isCollectionProduct ? `/collection/${key}` : `/product/${key}`,
      cardTitle,
      categories, // categories for location text override for product card 2024
      reviews,
      locations: getLocationInfo(data),
      currentPrice: typeof currentPrice === 'number' ? roundPrice(currentPrice) : null,
      pastPrice: typeof pastPrice === 'number' ? roundPrice(pastPrice) : null,
      percentOff: data?.price?.percentOff ?? null,
      productPromo,
      isCollectionProduct,
      isNew,
      isExclusive,
    };
  });

  return formattedCards;
};
