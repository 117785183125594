import { useLayoutEffect, useEffect } from 'react';

/**
 * A custom hook that uses `useLayoutEffect` if the `window` object is defined (i.e., in a browser environment),
 * otherwise it falls back to `useEffect` (i.e., in a server-side rendering environment).
 *
 * This ensures that the effect runs synchronously after all DOM mutations in the browser,
 * but still works in environments where `window` is not available.
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
