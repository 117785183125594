'use client';

import { useRouter } from 'next/compat/router';
import { useEffect, useState } from 'react';

export type UseHasQueryParamReturnValue = {
  status: 'pending' | 'ready';
  hasParam: boolean;
};

type UseHasQueryParam = (key: string, value: string) => UseHasQueryParamReturnValue;

/**
 * Custom hook to check if a specific query parameter exists in the URL and matches a given value.
 *
 * @param {string} key - The key of the query parameter to check.
 * @param {string} value - The value to compare against the query parameter's value.
 * @returns {Object} An object containing:
 * - `status`: A string indicating the status of the check ('pending' or 'ready').
 * - `hasParam`: A boolean indicating whether the query parameter exists and matches the given value.
 *
 * @example
 * const { status, hasParam } = useHasQueryParam('foo', 'bar');
 *
 * if (status === 'ready' && hasParam) {
 *   console.log('Query parameter "foo" exists and equals "bar"');
 * }
 */
export const useHasQueryParam: UseHasQueryParam = (key, value) => {
  const router = useRouter();

  const [status, setStatus] = useState<'pending' | 'ready'>('pending');
  const [hasParam, setHasParam] = useState<boolean>(false);

  useEffect(() => {
    setStatus('pending');

    if (router?.isReady) {
      const { query } = router;
      const expectedParam = query[key];

      setHasParam(expectedParam === value);
      setStatus('ready');
    }
  }, [router?.isReady]);

  return {
    status,
    hasParam,
  };
};
