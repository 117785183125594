import { GetStaticPaths, GetStaticProps, GetStaticPropsContext } from 'next';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

import type { PrismicDocument } from '@prismicio/types';
import * as Sentry from '@sentry/nextjs';

import {
  blogContentRelationship,
  ttdContentRelationship,
  clpContentRelationship,
  curatedCarouselTabContentRelationship,
} from '@virginexperiencedays/cms/constants';
import { fetchVedNavigationItems } from '../utils/precache/navigation-items';
import type { ProductMapType } from '@virginexperiencedays/products';

import { getTopProducts } from '../utils/getTopProducts';
import { ISR_CONTROL } from '../constants/revalidate';
import {
  categoryMappingsCache,
  listOfLinksCache,
  newProductsCache as productsCache,
} from '../utils/cache';
import { SlicesAdapter } from '../utils/prismic/SlicesAdapter';
import { createClient } from '../prismicio';

import Page from '../components/pages/custom/Page';
import { PAGE_TYPES } from '../components/tracking/gtm/consts';

import type { BasePageProps as PageProps } from '../components/pages/types';

const navigationServiceUrl = process?.env?.NEXT_PUBLIC_NAVIGATION_SERVICE_BASE_URL;
const prismicDocType = 'custom_site_page';

export const getStaticProps: GetStaticProps<PageProps> = async ({
  params,
  previewData,
}: GetStaticPropsContext) => {
  const slugs = Array.isArray(params.slug)
    ? params.slug.map((str) => str.toLowerCase().split('/')).flat()
    : params.slug.toLowerCase().split('/');
  const slug = slugs?.[0];
  const prismicClient = createClient({ previewData });
  const initialNavItems = await fetchVedNavigationItems(navigationServiceUrl);

  // fetch prismic page and top products data
  const topProductsPromise = getTopProducts();
  const prismicPagePromise = prismicClient.getByUID(prismicDocType, slug, {
    fetchLinks: [
      ...blogContentRelationship,
      ...ttdContentRelationship,
      ...clpContentRelationship,
      ...curatedCarouselTabContentRelationship,
    ],
  });

  // utilise allSettled as we don't want to error if topProducts fails
  const [topProductsResult, prismicPage] = await Promise.allSettled([
    topProductsPromise,
    prismicPagePromise,
  ]);

  let topProducts: ProductMapType = {};
  if (topProductsResult.status === 'fulfilled') {
    topProducts = topProductsResult.value as ProductMapType;
  } else {
    Sentry.captureException(new Error(topProductsResult.reason));
  }

  // if there is no Prismic page data we should fallback
  if (prismicPage.status === 'rejected') {
    return {
      notFound: true,
    };
  }

  const page = prismicPage.value as PrismicDocument;

  const slicesAdapter = new SlicesAdapter(
    page,
    productsCache,
    categoryMappingsCache,
    PHASE_PRODUCTION_BUILD
  );

  const prismicSlices = await slicesAdapter.integrate(page?.data?.slices ?? []);

  const listOfLinks = await listOfLinksCache.get();

  return {
    props: {
      trackingPageType: PAGE_TYPES.CustomSite,
      prismicPage: page,
      prismicSlices,
      slug,
      topProducts,
      initialNavItems,
      listOfLinks,
    },
    revalidate: ISR_CONTROL.ONCE_PER_DAY,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  // Prismic to fetch paths
  const prismicClient = createClient();
  const pages = await prismicClient.getAllByType(prismicDocType);

  // extend for any other pages that are explicitly defined using custom site page type
  const ignoreRoutes = ['gift-finder', 'gift-finder/results'];

  const staticPaths = pages
    .filter((page) => !ignoreRoutes.includes(page.uid))
    .map((page) => `/${page.uid}`);

  return {
    paths: staticPaths,
    fallback: 'blocking',
  };
};

export default Page;
